// eslint-disable-next-line no-unused-vars
import React from "react";
import { useStore } from "../../store";
import "./style.css";
export default function ServiceNew() {
  const { createService, user, getCategory } = useStore();

  const [imagePreviews, setImagePreviews] = React.useState([]);
  const [categories, setCategories] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [imageFiles, setImageFiles] = React.useState([]);
  const [errors, setErrors] = React.useState({});
  const [formData, setFormData] = React.useState({
    title: "",
    description: "",
    category: "",
    phone: "",
    price: "", // Agregar el precio como parte del formulario
  });
  const [isPriceEnabled, setIsPriceEnabled] = React.useState(false); // Estado para el checkbox

  const handleCheckboxChange = (event) => {
    setIsPriceEnabled(event.target.checked); // Actualiza si el precio está habilitado
  };


  React.useEffect(() => {
    const fetchCategories = async () => {
      const data = await getCategory();
      setCategories(data);
    };
    fetchCategories();
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageUpload = (event) => {
    const files = Array.from(event.target.files);
    const newImagePreviews = files.map((file) => URL.createObjectURL(file));

    if (imagePreviews.length + newImagePreviews.length > 7) {
      alert("Solo puedes subir hasta 5 imágenes.");
      return;
    }

    setImagePreviews((prevImages) => [...prevImages, ...newImagePreviews]);
    setImageFiles((prevFiles) => [...prevFiles, ...files]);
    setErrors((prevErrors) => ({ ...prevErrors, images: "" }));
  };

  const handleImageRemove = (imageToRemove) => {
    const index = imagePreviews.indexOf(imageToRemove);
    if (index > -1) {
      setImagePreviews((prevImages) =>
        prevImages.filter((image) => image !== imageToRemove)
      );
      setImageFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    handleImageUpload(event);
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.title) newErrors.title = "Nombre del servicio es requerido";
    if (!formData.description) {
      newErrors.description = "Descripción es requerida";
    } else if (formData.description.length < 100) {
      newErrors.description = "La descripción debe tener más de 100 caracteres";
    }
    if (!formData.category) newErrors.category = "Categoría es requerida";
    if (imageFiles.length === 0)
      newErrors.images = "Debe subir al menos una imagen";
    if (!user.phone) {
      if (!formData.phone) {
        newErrors.phone = "Número de teléfono es requerido";
      } else if (!/^\+549\d{10}$/.test(formData.phone)) {
        newErrors.phone =
          "Número de teléfono debe tener el formato +549 seguido de 10 dígitos, con una longitud total de 13 caracteres";
      }
    }
    return newErrors;
  };

  const handleSendService = async () => {
    setIsLoading(true);
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setIsLoading(false);
      return;
    }
    const service = {
      idCategory: formData.category,
      title: formData.title,
      description: formData.description,
      images: imageFiles,
      idUser: user.id,
      phone: formData.phone ? formData.phone : user.phone,
      price: isPriceEnabled ? formData.price : null, // Solo enviar precio si está habilitado
    };
    await createService(service);
    alert("Servicio creado correctamente");
    setIsLoading(false);
  };

  return (
    <div className="container">
      <div className="service-creation-container">
        <div className="service-details">
          <h2>Detalles del servicio</h2>
          <input
            type="text"
            name="title"
            placeholder="Nombre del servicio"
            value={formData.title}
            onChange={handleInputChange}
            disabled={isLoading}
          />
          {errors.title && <p className="error">{errors.title}</p>}
          <textarea
            name="description"
            placeholder="Descripción"
            value={formData.description}
            onChange={handleInputChange}
            disabled={isLoading}
          ></textarea>
                    {/* Checkbox y input para el precio */}
                    <div className="price-container">
            <label>
              <input
                type="checkbox"
                checked={isPriceEnabled}
                onChange={handleCheckboxChange}
                disabled={isLoading}
              />
              Agregar Precio 
            </label>
            <input
              type="number"
              name="price"
              placeholder="00"
              value={formData.price}
              onChange={handleInputChange}
              disabled={!isPriceEnabled || isLoading} // Bloqueado por defecto
            />
          </div>
          <p>{formData.description.length}/100 </p>
          {errors.description && <p className="error">{errors.description}</p>}
          {!user.phone && (
            <input
              type="text"
              name="phone"
              placeholder="Ingrese su número de teléfono"
              value={formData.phone}
              onChange={handleInputChange}
              disabled={isLoading}
            />
          )}

          {errors.phone && <p className="error">{errors.phone}</p>}
          <select
            name="category"
            value={formData.category}
            onChange={handleInputChange}
            disabled={isLoading}
          >
            <option value="">Seleccione una categoría</option>
            {categories &&
              categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
          </select>

          {errors.category && <p className="error">{errors.category}</p>}
          <button
            className="save-service-btn"
            onClick={handleSendService}
            disabled={isLoading}
          >
            {isLoading ? (
              <span className="spinner"></span>
            ) : (
              "Publicar servicio"
            )}
          </button>
        </div>
        <div className="upload-images">
          <h2>Subir imagenes</h2>
          <div
            className="upload-area"
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
            <input
              type="file"
              id="file-upload"
              multiple
              onChange={handleImageUpload}
              style={{ display: "none" }}
              disabled={imagePreviews.length >= 5 || isLoading}
            />
            <label
              htmlFor="file-upload"
              className={`upload-label ${
                imagePreviews.length >= 5 ? "disabled" : ""
              }`}
            >
              {imagePreviews.length < 5
                ? "Arrastre y suelte sus imágenes aquí o haga clic para cargarlas"
                : "Máximo 5 imágenes permitidas"}
            </label>
          </div>

          <div className="image-preview">
            {imagePreviews.map((image, index) => (
              <div key={index} className="image-container">
                <img src={image} alt={`uploaded-img-${index}`} />
                <button
                  className="remove-image-btn"
                  onClick={() => handleImageRemove(image)}
                >
                  &times;
                </button>
              </div>
            ))}
          </div>
          {errors.images && <p className="error">{errors.images}</p>}
        </div>
      </div>
    </div>
  );
}
