import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Navigate } from "react-router-dom";
import AnimatedRoutes from "./AnimatedRouted"; // Asegúrate de importar el nuevo componente

// Pages
import ServiceDetail from "./pages/Services";
import EditProfile from "./pages/EditProfile";
import RegisterScreen from "./pages/Register";
import LoginScreen from "./pages/Login";
import Profile from "./pages/Profile";
import ServiceNew from "./pages/CreateService";
import MisServicios from "./pages/ABM_Servicios/MisServicios";
import EditarServicio from "./pages/ABM_Servicios/EditarServicio";
import RegisterReferralsScreen from "./pages/RegisterReferrals/RegisterReferralsScreen";
import UserFavorite from "./pages/userFavorites"; // Corregido el nombre

// Components
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';

// Store
import { useStore } from "./store";
import AdminPage from "./pages/Admin";
import EditService from "./pages/EditService";
import ArvidaEmbebido from "./pages/ArvidaSeguros/arvidaEmbebido";
import CondicionesServicio from "./pages/condicionesDelServicio/condicionesServicio";

const HomeScreen = lazy(() => import("./pages/Home"));

function App() {
  const { showSidebar, login, user } = useStore();
  console.log("user", user);
  

  const routes = [
    { path: "/", element: <HomeScreen /> },
    { path: "/admin", element: user.idRol === "2" ? <AdminPage /> : <Navigate to="/" /> },
    { path: "/login", element: <LoginScreen /> },
    { path: "/profile/:id", element: <Profile /> },
    { path: "/register", element: <RegisterScreen /> },
    {
      path: "/edit-profile",
      element: login ? <EditProfile /> : <Navigate to="/login" />
    },
    {
      path: "/edit-service/:id",
      element: login ? <EditService /> : <Navigate to="/login" />
    },
    { path: "/service/:id", element: <ServiceDetail /> },
    { path: "/ABM_Servicios/NuevoServicio", element: <ServiceNew /> },
    { path: "/ABM_Servicios/MisServicios", element: <MisServicios /> },
    { path: "/referrals/:ReferralLink", element: <RegisterReferralsScreen /> },
    { path: "/ABM_Servicios/EditarServicio/:id", element: <EditarServicio /> },
    
    { path: "/favoritos", element: <UserFavorite /> },
    { path: "/arvida", element: <ArvidaEmbebido /> },
    { path: "/condiciones", element: <CondicionesServicio /> },
  ];

  return (
    <>
      <Router>
        <Navbar />
        <Suspense fallback={<div>Loading...</div>}>
          <AnimatedRoutes routes={routes} /> {/* Usa el componente AnimatedRoutes aquí */}
        </Suspense>
        {showSidebar && <Sidebar />}
      </Router>
    </>
  );
}

export default App;
