import React, { useEffect, useState } from 'react';
import './style.css'; // Asegúrate de importar el archivo CSS
import { useStore } from '../../store';
import { useLocation } from 'react-router-dom';

const CreateOrEditReview = ({idOwner, editRewiew}) => {
  const [reviewText, setReviewText] = useState('');
  const [rating, setRating] = useState(0);
  const [error, setError] = useState(false)
  const [showModal, setShowModal] = useState(false);
  const [editOrCreate, setEditOrCreate] = useState(false);

  const { createReview, editReview, user } = useStore();

  const location = useLocation();

  const handleChange = (eventOrValue) => {
    if (typeof eventOrValue === 'string') {
      setEditOrCreate(true)
    }
    const value = eventOrValue?.target?.value ?? eventOrValue;  // Si eventOrValue es un evento, usamos eventOrValue.target.value, sino usamos el valor directamente
    setReviewText(value);
    setShowModal(false)
  };
  
  const review = editRewiew?.Reviews.find((review) => {
    const filtrado = review.idService == editRewiew.id && review.idOwner == editRewiew.idUser;
    return filtrado;
  })
  

  const handleSubmit = async (event) => {

    event.preventDefault();
    if (rating === 0) {
      setError(true)
    } else {
      if (!editOrCreate) {
        console.log("createReview");
        
        const rewiew = await createReview({
          message: reviewText,
          qualification: rating,
          idUser: user.id,
          idService: location.pathname.split('/')[2],
          idOwner: idOwner
        });
        if (rewiew.status == 400) {
          setShowModal(true)
        } else {
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      } else {
        console.log("editReview");
        
        const rewiew = await editReview({
          message: reviewText,
          qualification: rating,
          idUser: user.id,
          idService: location.pathname.split('/')[2],
          idOwner: idOwner
        });

        setTimeout(() => {
          window.location.reload();
        }, 1000);
        
      }
      setReviewText('');
      setRating(0);

    }
  };

  const handleRatingClick = (rate) => {
    setRating(rate);
  };



  return (
    <div className="create-review-container">
       <h2>{editOrCreate ? 'Editar Reseña' : 'Crear Reseña'}</h2>

      <form onSubmit={handleSubmit}>
        <textarea
          value={reviewText}
          onChange={handleChange}
          rows="4"
          placeholder="Escribe tu reseña aquí..."
          required
        />

        <div className="rating-container">
          {[1, 2, 3, 4, 5].map((star) => (
            <button
              key={star}
              type="button"
              onClick={() => handleRatingClick(star)}
              style={{
                color: rating >= star ? 'gold' : 'gray',
              }}
            >
              ★
            </button>
          ))}
        </div>
        <div className='d-flex justify-content-center'>
          {error && <p className='error'>Por favor seleccione una calificación</p>}
        </div>

        <div className="buttons">
          <button type="submit">Enviar Reseña</button>
          <button type="button" onClick={() => { setReviewText(''); setRating(0); }}>Limpiar</button>
        </div>
      </form>
      {showModal && (
        <div className="modalRewiew">
          <div className="modal-content-rewiew">
            <h3>¡Gracias por tu reseña!</h3>
            <p>
              Ya has dejado una reseña para este producto/servicio. Lamentablemente, no puedes realizar otra reseña en este momento.
            </p>
            <p>
              Si deseas actualizar tu reseña, por favor edítala en lugar de crear una nueva. ¡Apreciamos tu participación!
            </p>
            <div className="modal-buttons">
              <button onClick={() => handleChange(review.message)}>Editar</button>
              <button >Aceptar</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateOrEditReview;