import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useStore } from "../../store";
import "./style.css";

const EditService = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const [errors, setErrors] = useState("");
  const [categories, setCategories] = useState([]);
  const [formData, setFormData] = useState();
  const [imagePreviews, setImagePreviews] = useState([]);
  const [imageFiles, setImageFiles] = React.useState([]);
  const [imageSend, setImageSend] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const { getServiceById, getCategory, deleteImage, updateService, user } =
    useStore();
  const [isPriceEnabled, setIsPriceEnabled] = React.useState(formData & formData?.price !== 0); // Estado para el checkbox

  console.log(" formData?.price",formData?.price !== 0);
  
  useEffect(() => {
    const fetchService = async () => {
      const data = await getCategory();
      setCategories(data);
      try {
        const data = await getServiceById(location.pathname.split("/")[2]);

        if (data) {
          setFormData(data.service);
        }
      } catch (error) {
        setErrors(`Error al cargar el servicio: ${error.message}`);
      }
    };

    fetchService();
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    handleImageUpload(event);
  };

  const handleImageUpload = (event) => {
    const files = Array.from(event.target.files);
    const newImagePreviews = files.map((file) => URL.createObjectURL(file));
    console.log(formData.Images.length);
    console.log(imagePreviews.length);
    if (imagePreviews.length + formData.Images.length > 5) {
      alert("Solo puedes subir hasta 5 imágenes.");
      return;
    }

    setImagePreviews((prevImages) => [...prevImages, ...newImagePreviews]);
    setImageSend((prevFiles) => [...prevFiles, ...files]);
    setErrors((prevErrors) => ({ ...prevErrors, images: "" }));
  };

  const handleImageRemove = (imageToRemove) => {
    const index = imagePreviews.indexOf(imageToRemove);
    if (index > -1) {
      setImagePreviews((prevImages) =>
        prevImages.filter((image) => image !== imageToRemove)
      );
      setImageFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    }
  };

  const handleImageRemoveFile = async (image) => {
    try {
      await deleteImage(image.url);
      setFormData((prevData) => ({
        ...prevData,
        Images: prevData.Images.filter((img) => img.url !== image.url),
      }));
    } catch (error) {
      alert(`Error al eliminar la imagen: ${error.message}`);
    }
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.title) newErrors.title = "Nombre del servicio es requerido";
    if (!formData.description) {
      newErrors.description = "Descripción es requerida";
    } else if (formData.description.length < 100) {
      newErrors.description = "La descripción debe tener más de 100 caracteres";
    }
    if (!formData.idCategory) newErrors.category = "Categoría es requerida";

    if (!user.phone) {
      if (!formData.phone) {
        newErrors.phone = "Número de teléfono es requerido";
      } else if (!/^\+549\d{10}$/.test(formData.phone)) {
        newErrors.phone =
          "Número de teléfono debe tener el formato +549 seguido de 10 dígitos, con una longitud total de 13 caracteres";
      }
    }
    return newErrors;
  };

  const handleSendService = async () => {
    setIsLoading(true);
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setIsLoading(false);
      return;
    }
    const service = {
      idCategory: formData.idCategory,
      title: formData.title,
      description: formData.description,
      images: imageSend,
      idUser: user.id,
      id: formData.id,
      price: Number(isPriceEnabled ? formData.price : undefined,) // Solo enviar si está habilitado

    };
    await updateService(service);
    alert("Servicio creado correctamente");
    setIsLoading(false);
  };

  useEffect(() => {
    if(formData){
      if(formData.Owner.id !== user.id){
        navigate('/');
      }}
  },[formData])
  console.log("formData",formData);
  
  console.log(isPriceEnabled);
  
  
  return (
    <div className="container">
      {formData && (
        <div className="service-creation-container">
          <div className="service-details">
            <h2>Detalles del servicio</h2>
            <input
              type="text"
              name="title"
              placeholder="Nombre del servicio"
              value={formData.title}
              onChange={handleInputChange}
              disabled={isLoading}
            />
            {errors.title && <p className="error">{errors.title}</p>}
            <textarea
              name="description"
              placeholder="Descripción"
              value={formData.description}
              onChange={handleInputChange}
              disabled={isLoading}
            ></textarea>
            <p>{formData.description.length}/100 </p>
            {errors.description && (
              <p className="error">{errors.description}</p>
            )}
            <div className="price-container">
              <label>
                <input
                  type="checkbox"
                  checked={isPriceEnabled} // Usa el estado local del checkbox
                  disabled={isLoading}
                  onChange={(e) => {
                    const isChecked = e.target.checked;
                    setIsPriceEnabled(isChecked);
                    setFormData((prevData) => ({
                      ...prevData,
                      price: isChecked ? prevData.price || "" : 0, // Mantén el valor anterior si existe
                    }));
                  }}
                />
                Agregar Precio
              </label>
              <input
                type="number"
                name="price"
                placeholder="00"
                value={isPriceEnabled ? formData.price || "" : ""} // Muestra el valor solo si está habilitado
                onChange={handleInputChange}
                disabled={!isPriceEnabled || isLoading} // Deshabilitado si el checkbox no está marcado o si está cargando
              />
            </div>
            
            {!user.phone && (
              <input
                type="text"
                name="phone"
                placeholder="Ingrese su número de teléfono"
                value={formData.phone}
                onChange={handleInputChange}
                disabled={isLoading}
              />
            )}

            {errors.phone && <p className="error">{errors.phone}</p>}
            <select
              name="idCategory"
              value={formData.idCategory}
              onChange={handleInputChange}
              disabled={isLoading}
            >
              <option value={""}>Seleccione una categoría</option>
              {categories &&
                categories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
            </select>

            {errors.category && <p className="error">{errors.category}</p>}
            <button
              className="save-service-btn"
              onClick={handleSendService}
              disabled={isLoading}
            >
              {isLoading ? (
                <span className="spinner"></span>
              ) : (
                "Publicar servicio"
              )}
            </button>
          </div>
          <div className="upload-images">
            <h2>Subir imagenes</h2>
            <div
              className="upload-area"
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              <input
                type="file"
                id="file-upload"
                multiple
                onChange={handleImageUpload}
                style={{ display: "none" }}
                disabled={imagePreviews.length + formData.Images.length >= 5 || isLoading}
              />
              <label
                htmlFor="file-upload"
                className={`upload-label ${
                  imagePreviews.length + formData.Images.length >= 5
                    ? "disabled"
                    : ""
                }`}
              >
                {imagePreviews.length + formData.Images.length < 5
                  ? "Arrastre y suelte sus imágenes aquí o haga clic para cargarlas"
                  : "Máximo 5 imágenes permitidas"}
              </label>
            </div>

            <div className="image-preview">
              {formData.Images.map((image, index) => (
                <div key={index} className="image-container">
                  <img
                    src={`https://www.contrataexpertos.com.ar/Servicios/Imagenes/${image.url}`}
                    alt={`uploaded-img-${index}`}
                  />
                  <button
                    className="remove-image-btn"
                    onClick={() => handleImageRemoveFile(image)}
                  >
                    &times;
                  </button>
                </div>
              ))}
              {imagePreviews.map((image, index) => (
                <div key={index} className="image-container">
                  <img src={image} alt={`uploaded-img-${index}`} />
                  <button
                    className="remove-image-btn"
                    onClick={() => handleImageRemove(image)}
                  >
                    &times;
                  </button>
                </div>
              ))}
            </div>
            {errors.images && <p className="error">{errors.images}</p>}
          </div>
        </div>
      )}
    </div>
  );
};

export default EditService;
