
import React from "react";
import { useEffect, useState } from "react";
import ServiceCard from "../../components/ServiceCard";
import { useStore } from "../../store";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion"; // Importa motion
import "./style.css";


const UserFavorite = () => {
  const { search, setServices, favoriteServices, user, services } = useStore();


  console.log("carga de favoritos");
  


  const serviciosFavoritos = services
  .filter(servicio => favoriteServices.some(fav => fav.idService === servicio.id && fav.idUser === user.id))
  .map(servicio => ({
    ...servicio,
    isFavorite: true // Sabemos que todos los servicios en este paso son favoritos
  }));

  console.log(serviciosFavoritos);
  
  
  return (
    <div className="containerHome">
      <motion.div
        className="content"
        initial={{ opacity: 0, y: -50 }} // Inicializa la opacidad y el desplazamiento
        animate={{ opacity: 1, y: 0 }} // Estado animado
        exit={{ opacity: 0, y: 50 }} // Estado de salida
        transition={{ duration: 0.5 }} // Duración de la transición
      >
        

        {true && (
          <motion.div
            initial={{ opacity: 0, y: 20 }} // Efecto inicial
            animate={{ opacity: 1, y: 0 }} // Animación de la lista de servicios
            exit={{ opacity: 0, y: 20 }} // Estado de salida
            transition={{ duration: 0.5 }} // Duración de la transición
            className="content"
          >
            {serviciosFavoritos && (
                serviciosFavoritos.map((service, index) => (
                    <div className="col" key={service.id || index}>
                    <ServiceCard service={service} />
                    </div>
                ))
                
            )}
          </motion.div>
        )}
      </motion.div>
    </div>
  );
};

export default UserFavorite;






