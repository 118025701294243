import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import axios from "axios";


import auth from "./action/auth.js";

import { URL } from "./http.js";
import serviceAction from "./action/services.js";
import adminAction from "./action/admin/user.js";
import adminServiceAction from "./action/admin/service.js";

import userServiceAction from "./action/user/service.js";

export const useStore = create(
  persist(
    (set, get) => ({
      favoriteServices: [],
      services: [],
      service: [],
      user: {},
      login: false,
      showSidebar: false,
      categories: null,
      search: '',
      whatsappClicks: 0, // Agregado para contar clics de WhatsApp
      error: {
        login: {
          status: false,
          message: "",
        },
        register: {
          status: false,
          message: "",
        }
      },
      statusFilter: 'Pendiente',
      setServices: (services) => set({ services }),


      setStatusFilter: (statusFilter) => {
        
        set({ statusFilter: statusFilter });
      },
      incrementWhatsappClicks: () => {
        const currentClicks = get().whatsappClicks;
        set({ whatsappClicks: currentClicks + 1 });
      },
      resetWhatsappClicks: () => {
        set({ whatsappClicks: 0 });
      },
      init: async () => { },

      getUserProfile: async (id) => {
        console.log("id usuario",id);
        
        const { data } = await axios.get(`${URL.URL_API}/api/user/${id}`);
        return data
      },
      updateProfile: async (user) => {
        const { data } = await axios.put(
          `${URL.URL_API}/api/user/${get().user.id}`,
          user,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        set({ user: data });
      },
      //AUTH 
      authLogout: (navigate) => auth.authLogout(navigate, set),
      authLogin: (usuario) => auth.authLogin(usuario, set, get),
      authRegister: (usuario) => auth.authRegister(usuario, set, get),
      authLoginGoogle: (usuario) => auth.authLoginGoogle(usuario, set, get),
      authRegisterGoogle: (usuario) => auth.authRegisterGoogle(usuario, set, get),

      //SERVICES
      getService: () => serviceAction.serviceGet(set),
      getServiceById: (id) => serviceAction.serviceById(id),
      deleteService: (id) => serviceAction.serviceDelete(id, set, get),
      updateService: (service) => serviceAction.serviceUpdate(service),
      createService: (service) => serviceAction.serviceCreate(set, get, service),

      //ADMIN SERVICE
      deleteServiceAdmin: (id) => adminServiceAction.adminServiceDelete(id, set, get),
      aproveService: (service) => adminServiceAction.adminServiceAprove(service, set, get),
      statusServiceChange: (id, status) =>  adminServiceAction.adminServiceStatus(id, status, set, get),
      adminServiceGet: () => adminServiceAction.adminServiceGet(set),
      //ADMIN USER 
      getUsers: () => adminAction.getUsers(),


      // USER SERVICE
      userServices : async() => {
        const token = localStorage.getItem("token");
        const { data } = await axios.get(`${URL.URL_API}/api/user/service`,
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        console.log("result",data)
        
        return data;
    },



      deleteImage: async (images) => {
        await axios.delete(`${URL.URL_API}/api/service/${images}/imagenes`);
      },



      putService: async (id, status, message) => {
        const { data } = await axios.put(`${URL.URL_API}/api/service/${id}`,
          {
            status: status,
            message: message,
          }
        );
        return data;
      },
      /*--------------------------- */
      /* CATEGORIES */
      handleModal: (value) => {
        set({ showSidebar: value });
      },
      getCategory: async () => {
        const { data } = await axios.get(`${URL.URL_API}/api/category`);

        return data;
      },
      /* CATEGORIES */
      /*--------------------------- */

      /*--------------------------- */
      /* REVIEWS */
      createReview: async (review) => {
        const { data } = await axios.post(`${URL.URL_API}/api/review`, review);

        return data;
      },
      editReview: async (review) => {
        console.log(review);
        
        const { data } = await axios.put(`${URL.URL_API}/api/review`, review);

        return data;
      },

      addFavorite: async (id, isFavorite) => {
        console.log(id);
        console.log(isFavorite);
        
        const { data } = await axios.post(`${URL.URL_API}/api/user/favorite`, {
          idService: id,
          isFavorite: isFavorite,
          idUser: get().user.id
        });
        //actualiza en el store los favoritos de usuario
        const currentUser = get().user;
        set({ user: { ...currentUser, Favorites: data } });
      },
      getUserFavorite: async (id) => {
        console.log(id);
        
        try {
          const { data } = await axios.get(`${URL.URL_API}/api/user/favorite/${id}`);
          console.log(data);
          
          set({ favoriteServices: data }); // Almacena los datos en el estado

        } catch (error) {
          const currentError = get().error;
          set({
            error: {
              ...currentError,
              login: {
                status: true,
                message: error.response?.data?.message || "Error fetching favorites",
              },
            },
          });
          return null;
        }
      },
      postCondicionesYPrivacidad: async (userId) => {
        console.log("postCondicionesYPrivacidad", userId);
        
        try {
          console.log("accept-terms");
          
          const response = await axios.post(`${URL.URL_API}/api/user/accept-terms`, {
            userId,
          });
          alert(response.data.message);
        } catch (error) {
          console.error("Error al aceptar términos:", error);
          alert("Hubo un error al aceptar los términos. Intente nuevamente.");
        }
      },
      setSearch: async (data) => {
        set({ search: data })
      }
    }),
    {
      name: "app-storage", // Nombre del item en el almacenamiento
      storage: createJSONStorage(() => localStorage), // Usamos sessionStorage
    }
  )
);
