// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

  h2, h3 {
    margin: 20px;
    color: #2c3e50;
    margin-top: 20px;
  }

  p {
    margin: 20px;
    line-height: 1.6;
    margin-bottom: 15px;
  }

  section {
    margin-bottom: 30px;
  }

  .content {
    margin-left: 20px;
    margin-right: 20px;
  }

  .content p {
    margin: 20px;
    text-align: justify;
  }`, "",{"version":3,"sources":["webpack://./src/pages/condicionesDelServicio/condicionesServicio.css"],"names":[],"mappings":";;EAEE;IACE,YAAY;IACZ,cAAc;IACd,gBAAgB;EAClB;;EAEA;IACE,YAAY;IACZ,gBAAgB;IAChB,mBAAmB;EACrB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,iBAAiB;IACjB,kBAAkB;EACpB;;EAEA;IACE,YAAY;IACZ,mBAAmB;EACrB","sourcesContent":["\n\n  h2, h3 {\n    margin: 20px;\n    color: #2c3e50;\n    margin-top: 20px;\n  }\n\n  p {\n    margin: 20px;\n    line-height: 1.6;\n    margin-bottom: 15px;\n  }\n\n  section {\n    margin-bottom: 30px;\n  }\n\n  .content {\n    margin-left: 20px;\n    margin-right: 20px;\n  }\n\n  .content p {\n    margin: 20px;\n    text-align: justify;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
