import React from "react";
import { motion } from "framer-motion"; // Importa motion
import { useNavigate } from "react-router-dom";
import "./condicionesServicio.css"



const CondicionesServicio = () => {
    const navigate = useNavigate();

    const menuprincipal = () => {
      navigate('/');
    }
  
  return (
    <div className="containerHome">
        <motion.div
            className="content"
            initial={{ opacity: 0, y: -50 }} // Inicializa la opacidad y el desplazamiento
            animate={{ opacity: 1, y: 0 }} // Estado animado
            exit={{ opacity: 0, y: 50 }} // Estado de salida
            transition={{ duration: 0.5 }} // Duración de la transición
        ></motion.div>
<h2>Términos y condiciones de uso</h2>
<p>Bienvenido a www.contrataexpertos.com (en adelante, “ContrataExpertos”), propiedad de Juan Giataganellis, CUIT N° 20-26942144-5.</p>

<p>El propósito de ContrataExpertos es facilitar la conexión entre profesionales de la construcción y potenciales clientes, brindando un espacio donde los profesionales pueden publicar sus servicios y los usuarios pueden contactarlos directamente a través de WhatsApp.</p>

<p>Los términos y condiciones que se describen a continuación (en adelante, los «Términos y Condiciones») tienen por objeto regular el uso que realicen las personas que ingresen a ContrataExpertos. Por «Usuario» de ContrataExpertos se entiende tanto a los registrados como a los visitantes (en adelante el/los “Usuario/s»). Al registrarse y/o navegar en ContrataExpertos, el Usuario presta su consentimiento al presente.</p>

<p>Los Términos y Condiciones, así como las políticas de privacidad y/o informaciones sobre ContrataExpertos se considerarán de aplicación para todo Usuario de ContrataExpertos desde el primer momento en que acceda al mismo. Los presentes Términos y Condiciones tienen carácter obligatorio y vinculante. Se aplican a todas las actividades realizadas en ContrataExpertos y se entenderán como formando parte de cada uno de los actos y contratos que se ejecuten o celebren mediante los sistemas de oferta y comercialización comprendidos en ContrataExpertos.</p>

<p>CUALQUIER PERSONA QUE NO ACEPTE ESTOS TÉRMINOS Y CONDICIONES GENERALES, LOS CUALES TIENEN UN CARÁCTER OBLIGATORIO Y VINCULANTE, DEBERÁ ABSTENERSE DE UTILIZAR EL SITIO Y/O LOS SERVICIOS.</p>

<p>La navegación y/o uso de cualquier forma de ContrataExpertos implica el conocimiento y la aceptación de los Términos y Condiciones.</p>

<p>En caso de preguntas o comentarios sobre los Términos y Condiciones o demás políticas y principios que rigen el uso de ContrataExpertos, los Usuarios podrán consultar a contrataexpertos.com.</p>

<h3>01 – Capacidad</h3>
<p>El uso y navegación de ContrataExpertos sólo puede ser realizado por personas que tengan capacidad legal para contratar de acuerdo a la legislación vigente en la República Argentina. No podrán utilizar ContrataExpertos las personas que no tengan esa capacidad, las personas menores de edad o Usuarios que hayan sido suspendidos temporalmente o inhabilitados definitivamente por el administrador de ContrataExpertos.</p>

<p>Los actos ejecutados por menores y/o incapaces en ContrataExpertos serán responsabilidad de sus padres, tutores, encargados o curadores, incluyendo cualquier cargo, facturación o daño que se derive de tal uso.</p>

<h3>02 – Celebración del contrato</h3>
<p>La información contenida en los Términos y Condiciones y los contenidos de ContrataExpertos no constituyen una oferta de venta, sino una invitación para contratar con el profesional publicitado. No existirá ningún contrato entre ContrataExpertos y el Usuario en relación con ningún servicio. ContrataExpertos no será responsable de los daños sufridos por los usuarios que eventualmente pudieran causar los profesionales contactados a través del sitio.</p>

<p>Para contratar un servicio, el Usuario deberá seguir el procedimiento indicado por el profesional.</p>

<p>ContrataExpertos no estará obligada a suministrar ningún producto o servicio que esté incluido en su sitio web.</p>

<h3>03 – Modificaciones de los Términos y Condiciones</h3>
<p>ContrataExpertos podrá modificar y/o sustituir total o parcialmente los Términos y Condiciones en cualquier momento, haciendo públicos en el sitio los términos modificados y/o nuevos, y no requerirá de los Usuarios su consentimiento. Todos los términos modificados entrarán en vigor en el momento mismo de su publicación.</p>

<p>El Usuario estará sujeto a los Términos y Condiciones vigentes al momento que realice el uso de ContrataExpertos. Los Usuarios son responsables de leer estos Términos y Condiciones cada vez que ingresen al sitio para ver si han sufrido modificaciones.</p>

<h3>04 – Registración</h3>
<p>La registración es gratuita y obligatoria para poder acceder a los servicios ofrecidos en ContrataExpertos. Es obligatorio completar el formulario de registro en todos los campos con datos válidos y verdaderos. El Usuario deberá mantener actualizados los datos personales proporcionados. ContrataExpertos podrá verificar la identidad del Usuario, pero no asume responsabilidad por la certeza de los datos provistos, siendo el Usuario responsable de la veracidad y autenticidad de los mismos.</p>

<h3>10 – Protección de datos personales</h3>
<p>ContrataExpertos se compromete a proteger la privacidad de los datos personales proporcionados por los Usuarios, conforme a la Ley de Protección de Datos Personales N° 25.326 de la República Argentina. Los datos recolectados serán utilizados únicamente para facilitar la prestación de los servicios ofrecidos en el sitio. Los Usuarios podrán ejercer sus derechos de acceso, rectificación y supresión de sus datos a través de contacto@contrataexpertos.com.</p>

<h3>11 – Política de Cookies</h3>
<p>ContrataExpertos utiliza cookies para mejorar la experiencia de navegación del Usuario. Las cookies permiten el reconocimiento del Usuario y la personalización del contenido. El Usuario puede deshabilitar el uso de cookies desde la configuración de su navegador, aunque esto puede afectar la funcionalidad del sitio.</p>

<h3>12 – Seguridad de la información</h3>
<p>ContrataExpertos implementa medidas de seguridad adecuadas para proteger los datos de los Usuarios. Sin embargo, el Usuario reconoce que ninguna transmisión por Internet es completamente segura y que existen riesgos inherentes al uso de redes públicas.</p>

<h3>13 – Enlaces a terceros</h3>
<p>El sitio puede contener enlaces a sitios web de terceros. ContrataExpertos no se hace responsable por el contenido ni por las políticas de privacidad de dichos sitios.</p>

<h3>14 – Duración y terminación</h3>
<p>El presente acuerdo tiene una duración indefinida. ContrataExpertos podrá suspender o terminar el acceso al sitio sin previo aviso en caso de incumplimiento de los Términos y Condiciones.</p>

<h3>15 – Legislación aplicable y jurisdicción</h3>
<p>Estos Términos y Condiciones se rigen por las leyes de la República Argentina. Cualquier controversia que surja en relación con el sitio será sometida a la jurisdicción de los tribunales ordinarios de la Ciudad Autónoma de Buenos Aires.</p>

<h3>16 – Propiedad intelectual</h3>
<p>El contenido del sitio, incluyendo textos, gráficos, logos, imágenes, íconos, software y demás materiales, son propiedad de ContrataExpertos o de sus respectivos propietarios y están protegidos por las leyes de propiedad intelectual. El Usuario se compromete a no utilizar dicho contenido sin el debido consentimiento previo y por escrito de ContrataExpertos o del titular correspondiente.</p>

<h3>17 – Uso indebido</h3>
<p>El Usuario se compromete a no utilizar ContrataExpertos con fines ilegales o prohibidos, y a no realizar ninguna acción que pueda dañar, deshabilitar, sobrecargar o deteriorar el funcionamiento del sitio. Esto incluye la distribución de virus, códigos maliciosos o la interferencia en el uso de otros usuarios.</p>

<h3>18 – Comentarios y valoraciones</h3>
<p>Los Usuarios pueden dejar comentarios y valoraciones sobre los servicios contratados, los cuales se publicarán en el perfil del profesional correspondiente. Estos comentarios deben ser verídicos y respetuosos. ContrataExpertos se reserva el derecho de eliminar comentarios que considere inapropiados o que violen las normas de la comunidad.</p>

<h3>19 – Responsabilidad de ContrataExpertos</h3>
<p>ContrataExpertos actúa como un intermediario entre los profesionales de la construcción y los clientes, pero no es responsable de los servicios prestados por los profesionales ni de los daños que puedan surgir de dichos servicios. El Usuario entiende que cualquier acuerdo de servicio es directamente entre el profesional y el cliente.</p>

<h3>20 – Suspensión de servicios</h3>
<p>ContrataExpertos podrá suspender temporalmente o dar de baja permanentemente el acceso de cualquier Usuario que incumpla los Términos y Condiciones. Esta suspensión puede aplicarse sin previo aviso y será a criterio exclusivo de ContrataExpertos.</p>

<h3>21 – Fuerza mayor</h3>
<p>ContrataExpertos no será responsable por el incumplimiento de los Términos y Condiciones en caso de fuerza mayor o eventos fuera de su control, tales como desastres naturales, actos de gobierno, fallos de proveedores, entre otros.</p>

<h3>22 – Comunicación</h3>
<p>El Usuario acepta recibir comunicaciones electrónicas relacionadas con los servicios, actualizaciones, promociones y novedades de ContrataExpertos. Dichas comunicaciones se enviarán por correo electrónico a la dirección proporcionada por el Usuario en su registro.</p>

<h3>23 – Cesión</h3>
<p>ContrataExpertos se reserva el derecho de ceder sus derechos y obligaciones a terceros, ya sea total o parcialmente, sin necesidad de consentimiento previo del Usuario.</p>

<h3>24 – Indemnización</h3>
<p>El Usuario se compromete a indemnizar y mantener indemne a ContrataExpertos, así como a sus directores, empleados y representantes, de cualquier reclamación, daño o perjuicio derivados de su uso del sitio o el incumplimiento de estos Términos y Condiciones.</p>

<h3>25 – Disposiciones generales</h3>
<p>Si alguna de las disposiciones de estos Términos y Condiciones fuera considerada inválida o inaplicable por un tribunal competente, las demás disposiciones seguirán siendo válidas y aplicables. ContrataExpertos podrá modificar o complementar estos Términos y Condiciones en cualquier momento, con la publicación de los nuevos términos en su sitio web.</p>


        <button style={{justifyContent: "center", alignItems: "center", display: "flex", margin: "0 auto",}} onClick={menuprincipal}>regresar</button>
    </div>
  );
};

export default CondicionesServicio;