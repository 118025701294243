import React from "react";
import "./WarningModal.css";

const WarningModal = ({ isOpen, onAccept, onReject, message }) => {
  console.log("modal zxx");
  
  if (!isOpen) return null;

  return (
    <div className="warning-modal">
      <div className="modal-content2">
        <div>
  <input type="checkbox" name="accept" id="accept" />
  <label htmlFor="accept">
    Acepto las{" "}
    <a href="/condiciones" className="link-celeste">
      condiciones del servicio y la política de privacidad
    </a>{" "}
    de contrataexpertos.com
  </label>
          </div>
        <div className="modal-buttons">
          <button onClick={onAccept} className="accept-button">Aceptar</button>
          <button onClick={onReject} className="reject-button">Rechazar</button>
        </div>
      </div>
    </div>
  );
};

export default WarningModal;
